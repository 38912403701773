// src/RedirectPage.js
import { useEffect } from 'react';

const RedirectPage = () => {
  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      window.location.href = 'https://wardrobeindia.in'; // Replace with your desired URL
    }, 4000);

    return () => clearTimeout(redirectTimer);
  }, []);
};

export default RedirectPage;
