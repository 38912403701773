// src/App.js
import React from 'react';
import RedirectPage from './components/RedirectPage';

function App() {
  return (
    <div className="App">
      <RedirectPage />
    </div>
  );
}

export default App;
